<template>
  <div>
    <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="exitForm()" />   
        <LanguageSwitcher mode="solid" @click.native="openLang()" />
      </div>
    </nav>
        <LangModal v-if="showLang" class="fixed z-50" @closemodal="closeLayer()" />
        <BackgroundLayer v-if="showLayer" @click.native="closeLayer()" />
    <div class="form-page" v-if="isMissingParams">
      <EmptyState
        icon-color="red"
        icon-class="bx bxs-error"
        :use-link="true"
        :btn-text="$t('empty_state.something_went_wrong.cta')"
        link-path="/destinations"
        :title="$t('empty_state.something_went_wrong.title')"
        :text="$t('empty_state.something_went_wrong.text')"
      />
    </div>

    <form v-else @submit.prevent="submit" action="" class="form">
      <div class="form-page">
        <i18n path="page.trip_request.request.title" class="form-page-title">
          <span class="title-country">{{ country }}</span>
        </i18n>
        <RatingSummary
          v-if="!loading && reviews.length > 0"
          :rating="rating"
          :reviews="reviews.length"
          class="mt-4"
        />
        <label class="label">
          {{ $t("trip_request.form.title") }}
        </label>
        <div class="checkbox-container">
          <CheckboxBig
            @input.native="toggleAccommodation"
            :click="isAccommodation === true"
            :category="$t('trip_request.form.checkbox_1.title')"
            :description="$t('trip_request.form.checkbox_1.description')"
            name="accommodation"
            class="checkbox-big"
            :img="
              require('@/assets/img/heroes-sections/accommodation-in-ourika-morocco-travel-afrique.jpg')
            "
          />

          <CheckboxBig
            @input.native="toggleExperience"
            :category="$t('trip_request.form.checkbox_2.title')"
            :description="$t('trip_request.form.checkbox_2.description')"
            :click="isExperience === true"
            name="experiences"
            class="checkbox-big"
            :img="
              require('@/assets/img/heroes-sections/feeding-giraffe-travel-afrique.jpg')
            "
          />
        </div>
        <ButtonPrimary class="desktop-cta" :text="$t('trip_request.button')" />
      </div>
      <div class="bottom-nav">
        <div class="wrapper">
          <ButtonPrimary
            class="block h-12 px-8 w-full"
            :text="$t('trip_request.button')"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import CheckboxBig from "@/components/checkboxes/CheckboxBig";
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import api from "@/api";
import ButtonIcon from "@/components/buttons/ButtonIcon";
import RatingSummary from "@/components/review/RatingSummary.vue";
import EmptyState from "@/components/EmptyState";
import LangModal from "@/components/modals/LangModal";
import BackgroundLayer from "@/components/BackgroundLayer";
import LanguageSwitcher from "@/components/switchers/LanguageSwitcher";
const countries = {
  en: [
    {
      country: "Benin",
      slug: "benin",
      text: "Benin enchants with the breathtaking scenery of the palm-fringed beaches of the Atlantic coast, the striking landscape of the north and the Parc National de la Pendjari. From lions to cheetahs to elephants, several hundred species of animals can be found here.",
      img: "benin-travel-afrique.jpg",
      store: "Benin",
    },
    {
      country: "Cape Verde",
      slug: "cape-verde",
      text: "A stunning island chain with a mesmerizing mix of mountains, beaches and serene coastline villages. Hidden behind rocky peaks are deep green valleys of flowers and sugar cane, ideal for epic hikes.",
      img: "cape-verde-travel-afrique.jpg",
      store: "Cape Verde",
    },
    {
      country: "Côte d'Ivoire",
      slug: "cote-d-ivoire",
      text: "Discover a real tropical paradise within a nation that is revamping its way of life and culture while preserving its roots.  With sandy beaches dotted with starfish, palm forests and orange roads, Cote d'Ivoire offers a unique traveling experience.",
      img: "cote-d-ivoire-travel-afrique.jpg",
      store: "Côte d'Ivoire",
    },
    {
      country: "Democratic Republic of the Congo",
      slug: "democratic-republic-of-the-congo",
      text: "With vast expanses of rainforest, roaring rivers and fuming volcanoes, the Democratic Republic of Congo is the epitome of the African adventure.",
      img: "dr-congo-travel-afrique.jpg",
      store: "Democratic Republic of the Congo",
    },
    {
      country: "Ghana",
      slug: "ghana",
      text: "Boasting inviting beaches, a beautiful countryside, a lush culture, vibrant cities, a diverse wildlife, and convenient transportation, it's no wonder Ghana has become a popular travel destination.",
      img: "ghana-travel-afrique.jpg",
      store: "Ghana",
    },
    {
      country: "Kenya",
      slug: "kenya",
      text: "Welcome to Kenya, where you will find vast savannahs with huge herds of wildlife, snow-capped equatorial mountains and indigenous peoples who breathe life and warmth into the earth.",
      img: "kenya-travel-afrique.jpg",
      store: "Kenya",
    },
    {
      country: "Madagascar",
      slug: "madagascar",
      text: "Embark on a journey to Madagascar, an island paradise teeming with unique wildlife and lush landscapes. Dive into its rich culture and explore the mystical allure of its ancient rainforests and vibrant coral reefs.",
      img: "madagascar-travel-afrique.jpg",
      store: "Madagascar",
    },
    {
      country: "Morocco",
      slug: "morocco",
      text: "A country bursting with incredible diversity. Here is where you will find imposing mountain ranges, ancient cities, vast deserts - and warm hospitality.",
      img: "morocco-travel-afrique.jpg",
      store: "Morocco",
    },
    {
      country: "Namibia",
      slug: "namibia",
      text: "It is home to the oldest desert in the world a breathtaking sea of sand with otherworldly sand dunes that magically slope into the sea and merge with it - it is hard to imagine a picture-perfect landscape that Namibia does not possess.",
      img: "namibia-travel-afrique.jpg",
      store: "Namibia",
    },
    {
      country: "Rwanda",
      slug: "rwanda",
      text: "The land of a thousand hills is known for its endless mountains and is home to some of the world's last living mountain gorillas in the bamboo forests.",
      img: "rwanda-travel-afrique.jpg",
      store: "Rwanda",
    },
    {
      country: "Senegal",
      slug: "senegal",
      text: "Tantalizing and tranquil at the same time, Senegal casts a spell over you. Here sophistication meets metropolitan mayhem, dense traffic, vibrant markets and a glittering nightlife.Wether you want to mingle with the hipsters of urban Africa or revel in the sounds of nature, you are guaranteed to find the right place in Senegal.",
      img: "senegal-travel-afrique.jpg",
      store: "Senegal",
    },
    {
      country: "South Africa",
      slug: "south-africa",
      text: "From finest sandy beaches lapped by two oceans to starry desert skies and rugged, magnificent mountains, this is truly a country of breathtaking diversity.",
      img: "south-africa-travel-afrique.jpg",
      store: "South Africa",
    },
    {
      country: "Tanzania",
      slug: "tanzania",
      text: "Wilderness, pristine beaches, warm and hospitable people, Serengeti, Ngorongoro, Kilimanjaro, the Zanzibar archipelago - Tanzania offers all of that and so much more in an enticing, adventure-filled experience.",
      img: "tanzania-travel-afrique.jpg",
      store: "Tanzania",
    },
  ],
  fr: [
    {
      country: "Bénin",
      slug: "benin",
      text: "Le Bénin enchante par les paysages époustouflants des plages bordées de palmiers de la côte atlantique, les paysages saisissants du nord et le parc national de la Pendjari. Des lions aux guépards en passant par les éléphants, on y trouve plusieurs centaines d'espèces d'animaux.",
      img: "benin-travel-afrique.jpg",
      store: "Benin",
    },
    {
      country: "Cap-Vert",
      slug: "cape-verde",
      text: "Une chaîne d'îles étonnante avec un mélange envoûtant de montagnes, de plages et de villages côtiers sereins. Derrière les pics rocheux se cachent des vallées vertes profondes où poussent des fleurs et de la canne à sucre, idéales pour des randonnées épiques.",
      img: "cape-verde-travel-afrique.jpg",
      store: "Cape Verde",
    },
    {
      country: "Côte d'Ivoire",
      slug: "cote-d-ivoire",
      text: "Découvrez un véritable paradis tropical au sein d'une nation qui renouvelle son mode de vie et sa culture tout en préservant ses racines. Avec ses plages de sable parsemées d'étoiles de mer, ses forêts de palmiers et ses routes orange, la Côte d'Ivoire offre une expérience de voyage unique.",
      img: "cote-d-ivoire-travel-afrique.jpg",
      store: "Côte d'Ivoire",
    },
    {
      country: "République démocratique du Congo",
      slug: "democratic-republic-of-the-congo",
      text: "Avec ses vastes étendues de forêt tropicale, ses rivières rugissantes et ses volcans fumants, la République démocratique du Congo est l'exemple même de l'aventure africaine.",
      img: "dr-congo-travel-afrique.jpg",
      store: "Democratic Republic of the Congo",
    },
    {
      country: "Ghana",
      slug: "ghana",
      text: "Avec ses plages accueillantes, ses paysages magnifiques, sa culture luxuriante, ses villes animées, sa faune diversifiée et ses moyens de transport pratiques, il n'est pas étonnant que le Ghana soit devenu une destination touristique populaire.",
      img: "ghana-travel-afrique.jpg",
      store: "Ghana",
    },
    {
      country: "Kenya",
      slug: "kenya",
      text: "Bienvenue au Kenya, où vous trouverez de vastes savanes avec d'immenses troupeaux d'animaux sauvages, des montagnes équatoriales aux sommets enneigés et des peuples indigènes qui insufflent vie et chaleur à la terre.",
      img: "kenya-travel-afrique.jpg",
      store: "Kenya",
    },
    {
      country: "Madagascar",
      slug: "madagascar",
      text: "Embarque pour un voyage à Madagascar, une île paradisiaque regorgeant d'une faune unique et de paysages luxuriants. Plonge dans sa riche culture et explore l'allure mystique de ses anciennes forêts tropicales et de ses vibrants récifs coralliens.",
      img: "madagascar-travel-afrique.jpg",
      store: "Madagascar",
    },
    {
      country: "Maroc",
      slug: "morocco",
      text: "Un pays qui regorge d'une incroyable diversité. C'est ici que vous trouverez d'imposantes chaînes de montagnes, des villes anciennes, de vastes déserts - et une hospitalité chaleureuse.",
      img: "morocco-travel-afrique.jpg",
      store: "Morroco",
    },
    {
      country: "Namibie",
      slug: "namibia",
      text: "Elle abrite le plus vieux désert du monde, une mer de sable à couper le souffle, avec des dunes de sable d'un autre monde qui s'inclinent comme par magie vers la mer et se confondent avec elle. Il est difficile d'imaginer un paysage parfait que la Namibie ne possède pas.",
      img: "namibia-travel-afrique.jpg",
      store: "Namibia",
    },
    {
      country: "Rwanda",
      slug: "rwanda",
      text: "Le pays des mille collines est connu pour ses montagnes sans fin et abrite quelques-uns des derniers gorilles de montagne vivants dans les forêts de bambous.",
      img: "rwanda-travel-afrique.jpg",
      store: "Rwanda",
    },
    {
      country: "Sénégal",
      slug: "senegal",
      text: "À la fois séduisant et tranquille, le Sénégal vous envoûte. Que vous souhaitiez vous mêler aux hipsters de l'Afrique urbaine ou vous délecter des sons de la nature, vous êtes sûr de trouver le bon endroit au Sénégal.",
      img: "senegal-travel-afrique.jpg",
      store: "Senegal",
    },
    {
      country: "Afrique du Sud",
      slug: "south-africa",
      text: "Des plus belles plages de sable fin baignées par deux océans aux ciels étoilés du désert, en passant par les montagnes escarpées et magnifiques, ce pays est d'une diversité à couper le souffle.",
      img: "south-africa-travel-afrique.jpg",
      store: "South Africa",
    },
    {
      country: "Tanzanie",
      slug: "tanzania",
      text: "La nature sauvage, les plages immaculées, les gens chaleureux et hospitaliers, le Serengeti, le Ngorongoro, le Kilimandjaro, l'archipel de Zanzibar - la Tanzanie offre tout cela et bien plus encore dans une expérience séduisante et pleine d'aventures.",
      img: "tanzania-travel-afrique.jpg",
      store: "Tanzania",
    },

  ],
};

export default {
  name: "request",
  components: {
    CheckboxBig,
    ButtonIcon,
    RatingSummary,
    EmptyState,
    LangModal,
    LanguageSwitcher,
    BackgroundLayer
  },
  data() {
    return {
      showLayer: false,
      showLang: false,
      reviews: [],
      rating: undefined,
      loading: true,
      isMissingParams: undefined,
      lang: localStorage.getItem("lang") || "en",
      language: { en: "English", fr: "French" }
    };
  },
  computed: {
    ...mapGetters("request", ["step"]),
    ...mapGetters({
      requestEntry: "request/requestEntry",
      isExperience: "request/isExperience",
      isAccommodation: "request/isAccommodation",
      isTripDetailsPage: "request/isTripDetailsPage",
      destination: "request/destination",
    }),
    country() {
      let country = this.$route.query.country;
      let lang = this.lang;
      let le = ["Ghana", "Bénin", "Cap-Vert", "Kenya", "Maroc", "Sénégal", "Rwanda", "Madagascar"];
      let l = ["Afrique du Sud"];
      let la = ["Côte d'Ivoire", "République démocratique du Congo", "Namibie", "Tanzanie"];

      if (le.includes(country) && lang === "fr") return `le ${country}`;
      if (l.includes(country) && lang === "fr") return `l'${country}`;
      if (la.includes(country) && lang === "fr") return `la ${country}`;

      return country;
    },
    distinction() {
      if (this.rating >= 4.25) return "excellent";
      if (this.rating >= 3.75 && this.rating < 4.25) return "good";
      if (this.rating >= 3.25 && this.rating < 3.75) return "average";
      if (this.rating >= 2.75 && this.rating < 3.25) return "poor";
      if (this.rating >= 2.25 && this.rating < 2.75) return "bad";
      return "miserable";
    },
  },
  methods: {
    ...mapActions({
      setIsAccommodation: "request/setIsAccommodation",
      setIsExperience: "request/setIsExperience",
      setIsTripDetailsPage: "request/setIsTripDetailsPage",
      setTotalPages: "request/setTotalPages",
      setIsTravelerDetailsPage: "request/setIsTravelerDetailsPage",
      setFormData: "request/setFormData"
    }),
    toggleBodyOverflow() {
      document.body.classList.toggle("overflow-y-hidden", this.showLayer);
    },
    openLang() {
      this.showLang = !this.showLang;
      this.showLayer = true;
      this.toggleBodyOverflow();
    },
    closeLayer() {
      this.showLang = false;
      this.showLayer = false;
      this.toggleBodyOverflow();
    },
    getRating(reviews) {
    console.log(reviews)
      const sum = reviews.reduce((acc, review) => acc + parseInt(review.rating), 0);
      return parseFloat((sum / reviews.length).toFixed(1));
    },
    dayjs(...args) {
      return dayjs(...args);
    },
    exitForm() {
      const requestEntry = this.requestEntry === "destinations" ? "destinations" : "";
      const a = document.createElement("a");
      a.href = `/${requestEntry}`;
      a.click();
    },
    validate() {
      return this.isExperience || this.isAccommodation;
    },
    routerPush(path) {
      this.$router.push({
        path,
        query: {
          q: this.$route.query.q,
          country: this.$route.query.country,
          lang: this.$route.query.lang,
        }
      });
    },
    submit() {
      if (this.validate()) {
        this.setIsTripDetailsPage(true);
        this.$store.commit("request/SET_STEP_INCREMENT", 1);
        const totalPages = this.isAccommodation && this.isExperience ? 6 : 5;
        this.setTotalPages(totalPages);
        this.setIsTravelerDetailsPage(true);
        const country = countries.en.find(country => country.slug === this.$route.query.q);

        if (!this.isAccommodation) {
          this.setFormData({ singleRoom: 0, mealPlan: "None" });
        }

        this.setFormData({
          country: country.store,
          lang: this.lang,
          language: this.language[this.lang],
          experiences: "",
          accommodations: ""
        });
        this.routerPush("/request/trip-details");
      } else {
        this.$notify({
          type: "error",
          text: this.$t("trip_request.form.error"),
        });
      }
    },
    toggleAccommodation() {
      this.setIsAccommodation(!this.isAccommodation);
    },
    toggleExperience() {
      this.setIsExperience(!this.isExperience);
    },
    calculateStartTime() {
      const startTime = dayjs(new Date()).format("hh:mm:ss");
      localStorage.setItem("start_time", startTime);
    },
  },
  async created() {
    const { q, country } = this.$route.query;
    this.isMissingParams = q === undefined || country === undefined;

    if (!this.isMissingParams) {
      try {
        const res = await api.getReviews();
        this.loading = false;
        if (res.data.reviews) {
          this.rating = this.getRating(res.data.reviews);
          this.reviews = [...res.data.reviews];
        }
      } catch (err) {
        console.log("Error fetching reviews:", err);
      }
    }
  },
  beforeCreate() {
    const { q, lang: language } = this.$route.query;
    if (language && language !== this.lang) {
      const countryList = language === "fr" ? countries.fr : countries.en;
      const country = countryList.find(c => c.slug === q);
      if (country) {
        this.$router.push({
          path: "/request",
          query: { q, country: country.country, lang: this.lang }
        });
      }
    }
  },
  mounted() {
    if (!localStorage.getItem("start_time")) this.calculateStartTime();
  },
};
</script>


<style scoped>
.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}

.form {
  @apply w-full px-4 pb-48 mt-4 xs:mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply mb-8 font-sans text-2xl font-bold  sm:text-3xl text-black-base;
}

.checkbox-container {
  @apply flex flex-wrap md:flex-nowrap gap-4;
}

.checkbox-big {
  @apply w-full md:mb-8 md:w-calc-1/2;
}

.wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}

.title-country {
  @apply inline-block text-black-base font-bold;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #ffab1a;
  text-underline-offset: 8px;
  text-decoration-thickness: 6px;
}

.label {
  @apply font-semibold text-base xs:text-lg text-black-base mb-6 inline-block mt-12;
}
</style>
